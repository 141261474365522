<div class="report-header d-flex flex-column">
  <div class="position-relative pt-5 mt-5" *ngIf="!report">
    <app-loader></app-loader>
  </div>
  <ng-container *ngIf="report">
    <div class="back-button d-flex align-items-center pointer" (click)="goBack()">
      <div attr.aria-label="icon back" id="icon-back" class="icon icon-12 icon-back me-2"></div>
      <label class="font-size-13 trenda-regular pointer">
        {{ "REPORT_DETAILS.HEADER.RETURN" | translate }}
      </label>
    </div>
    <div class="report-info d-flex flex-column flex-sm-row mb-0 mb-sm-5">
      <div class="icon-document icon icon-45 me-3 mb-3 mb-sm-0"
        [ngClass]="report?.type === REPORT ? 'icon-report' : 'icon-dashboard'"></div>
      <div class="report-details d-flex flex-column">
        <div class="d-flex align-items-center font-size-12 fw-bold trenda-regular text-light-grey ">
          <span class="me-2 px-2"
            [ngStyle]="{'background-color': report?.type === REPORT ? '#1C5074' : '#543C93'}">{{report?.type}}</span>
          <span>{{report?.code | uppercase}}</span>
        </div>
        <div class="report-name font-size-24 fw-bold trenda-regular">
          {{report?.name}}
        </div>
      </div>
      <div class="report-favorite d-flex justify-content-end justify-content-sm-start mb-sm-0 mb-2  ms-4">
        <button type="button" role="button" id="add-favorite" class="btn-favorite" (click)="onToggleFavorite()"
          attr.aria-label="toggle favorite">
          <div id="favorites-icon" class="icon icon-16 mx-0" [ngClass]="report?.isFavorite ? 'icon-star' : 'icon-favorites'">
          </div>
        </button>
      </div>
    </div>
    <div class="report-actions d-flex flex-column flex-lg-row">
      <div class="report-source d-flex align-items-start justify-content-start pt-4 order-1 order-lg-0">
        <div class="w-75 d-flex flex-column align-items-center justify-content-center my-2">
          <div class="data-source-title">{{ "REPORT_DETAILS.HEADER.DATA_SOURCES" | translate }}</div>
          <p class="d-flex data-source-container text-truncate">
            <ng-container *ngFor="let source of dataSources; let isLast=last">
              <span class="data-source-value my-2">{{source}}{{isLast ? '' : ', '}}</span>
            </ng-container>
          </p>
          <button *ngIf="dataSources?.length" (click)="openModal()" data-bs-toggle="modal" data-bs-target="#dataSourceModal" id="btn-see-all" type="button" role="button" attr.aria-label="sell all button"
            class="data-source-btn px-4">{{ "HOME.SECTION.SEE_ALL" | translate }}</button>
        </div>
        <div class="w-50 d-flex flex-column align-items-center justify-content-center my-2 pe-4">
          <div class="data-source-title mb-1">
            {{ "REPORT_DETAILS.HEADER.LATEST_UPDATE" | translate }}
          </div>
          <span class="data-source-value">{{latestUpdate | dateFormat : 'medium' | titlecase}} {{latestUpdate ? (latestUpdate  | date:"HH:mm") : ''}}</span>
        </div>
      </div>
      <div class="btn-actions d-flex align-items-end ms-5 justify-content-end" [ngClass]="{'expanded': isExpanded}">
        <button class="action-toggle d-lg-none me-2" (click)="isExpanded = !isExpanded" >
          <img alt="action button" class="" src="assets/images/mobile-burger-icon.svg"/>
        </button>
        <button type="button" id="btn-print" role="button" *ngIf="report?.sourceType === sourceTypes.POWER_BI && !report?.paginated"
          (click)="printReport()" class="d-flex align-items-center font-size-14 justify-content-center">
          <div attr.aria-label="icon print" id="icon-print" class="icon icon-20 icon-print me-3  d-none d-lg-block "></div>
          {{ "REPORT_DETAILS.HEADER.PRINT_BTN" | translate }}
        </button>
        <ng-container *ngIf="report?.sourceType !== sourceTypes.SAP_BO" >
          <button *ngIf="report?.sourceType === sourceTypes.POWER_BI else exportFile" [disabled]="loadingExport" type="button" role="button" id="btn-export" data-bs-toggle="modal" data-bs-target="#dataSourceModal" (click)="openExportModal()"
          class="d-flex align-items-center font-size-14 justify-content-center mx-3 export-btn">
          <div attr.aria-label="icon export" id="icon-export" class="icon icon-20 icon-export me-3 d-none d-lg-block"></div>
          {{"REPORT_DETAILS.HEADER.EXPORT_BTN" | translate }}
          <div *ngIf="loadingExport"  class="spinner-border spinner-border-sm text-light ms-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <ng-template #exportFile>
          <button [disabled]="loadingExport" type="button" role="button" id="btn-export"
          class="d-flex align-items-center font-size-14 justify-content-center mx-3 export-btn" (click)="openExportModal()">
          <div attr.aria-label="icon export" id="icon-export" class="icon icon-20 icon-export me-3 d-none d-lg-block"></div>
          {{"REPORT_DETAILS.HEADER.EXPORT_BTN" | translate }}
          <div *ngIf="loadingExport"  class="spinner-border spinner-border-sm text-light ms-2" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        </ng-template>
        </ng-container>
        <button (click)="onShareClicked()" type="button" role="button" id="btn-share"
          class="d-flex align-items-center font-size-14 justify-content-center">
          <div attr.aria-label="icon share" id="icon-share" class="icon icon-20 icon-share me-3 d-none d-lg-block"></div>
          {{"REPORT_DETAILS.HEADER.SHARE_BTN" | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
<div class="modal fade" id="dataSourceModal" aria-labelledby="exampleModalLabel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header px-4">
        <h5 *ngIf="modalType === 'SEE_ALL'" class="modal-title" id="exampleModalLabel">{{ "REPORT_DETAILS.HEADER.DATA_SOURCES" | translate }}</h5>
        <div *ngIf="modalType === 'EXPORT'" class="d-flex align-items-center">
          <div attr.aria-label="icon export" id="icon-export-modal" class="icon icon-18 icon-export me-3"></div>
          <h5 class="modal-title mt-2" id="exampleModalLabel">{{"REPORT_DETAILS.HEADER.MODALS.EXPORT_TITLE" | translate }}</h5>
        </div>

        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body px-5 py-4">
        <div class="d-flex flex-wrap" *ngIf="modalType === 'SEE_ALL'; else export">
          <ng-container *ngFor="let source of dataSources; let isLast=last">
            <span class="data-source-badge px-2 mb-3">{{source}}</span>
          </ng-container>
        </div>
        <ng-template #export>
          <div class="position-relative export-container d-flex align-items-center justify-content-around mt-5">
            <div class="d-flex flex-column align-items-center justify-content-center icon-export-file" (click)="setExportType(exportTypeEnum.PDF)"
            [class.active-export] = "exportType === exportTypeEnum.PDF">
              <div attr.aria-label="icon export pdf" id="icon-pdf" class="icon icon-65 icon-export-pdf"></div>
              <label>PDF</label>
            </div>
            <div *ngIf="!report?.paginated" class="d-flex flex-column align-items-center justify-content-center icon-export-file" (click)="setExportType(exportTypeEnum.PNG)"
            [class.active-export] = "exportType === exportTypeEnum.PNG">
              <div attr.aria-label="icon export png" id="icon-png" class="icon icon-65 icon-export-png"></div>
              <label>PNG</label>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center icon-export-file" (click)="setExportType(exportTypeEnum.PPT)"
            [class.active-export] = "exportType === exportTypeEnum.PPT">
              <div attr.aria-label="icon export ppt" id="icon-ppt" class="icon icon-65 icon-export-ppt"></div>
              <label>Powerpoint</label>
            </div>
            <div *ngIf="report?.paginated" class="d-flex flex-column align-items-center justify-content-center icon-export-file" (click)="setExportType(exportTypeEnum.EXCEL)"
            [class.active-export] = "exportType === exportTypeEnum.EXCEL" >
              <div attr.aria-label="icon export excel" id="icon-excel" class="icon icon-65 icon-export-excel"></div>
              <label>Excel</label>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="modal-footer p-5" *ngIf="modalType === 'EXPORT'">
        <button type="button" id="btn-cancel" class="btn btn-transparent btn-cancel trenda-regular" data-bs-dismiss="modal" (click)="cancelExport()">{{"REPORT_DETAILS.HEADER.MODALS.CANCEL" | translate }}</button>
        <div [class.not-allowed] = "!exportType" >
          <button type="button" [disabled]="!exportType" id="btn-export-report" class="btn btn-export trenda-bold" data-bs-dismiss="modal" (click)="exportFile()">{{"REPORT_DETAILS.HEADER.MODALS.EXPORT_TITLE" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
