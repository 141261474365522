import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Subscription } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { sourceTypeEnum } from 'src/app/core/enum/report.enum';
import { VisibilityEnum } from 'src/app/core/enum/visibility.enum';
import { IELearning } from 'src/app/core/interfaces/e-learning.interface';
import { DataSource, PlatformItem } from 'src/app/core/interfaces/platform.interface';
import { IAttachment, IReport, ITag, reportSourceTypeEnum, ReportTypeEnum } from 'src/app/core/interfaces/report.interface';
import { IGroup, ISectionAdmin } from 'src/app/core/interfaces/section.interface';
import { UploadService } from 'src/app/core/services/upload.service';
import { DashboardService } from 'src/app/pages/admin/dashboard/dashboard.service';
import { SnackbarService } from 'src/app/shared/snackbar/snackbar.service';
import { ReportService } from '../add-report/report.service';

@Component({
  selector: 'app-add-report-step-two',
  templateUrl: './add-report-step-two.component.html',
  styleUrls: ['./add-report-step-two.component.scss']
})

export class AddReportStepTwoComponent implements OnInit, OnDestroy {
  readonly sourceTypesLabels: { [key: string]: string; } = { 'power-bi': 'POWER_BI', 'sap-bo': 'SAP_WEBI', 'link': 'LINK', 'file': 'FILE' };
  reportForm!: FormGroup;
  visibilityEnum = VisibilityEnum;
  reportTypeEnum = ReportTypeEnum;

  childrenSelected: PlatformItem[] = [];
  reportAttachments: IAttachment[] = [];
  sections: ISectionAdmin[] = [];
  tags: ITag[] = [];
  elearnings: IELearning[] = [];
  dataSources: DataSource[] = [];
  groups: IGroup[] = [];

  fileName!: string;
  sourceName: string = ""
  sourceType!: string
  idFile!: number | null;
  idImage!: number | null;
  skip = 0;
  limit = 50;

  editForm = false;
  displayFileInput = true;
  displayImageInput = true;
  displayAttachmentInput = true;
  startUploadingAttachments = false;

  attachments: { id: number[], index: number }[] = [];
  imageId: { id: number | null, index: number }[] = []
  step!: number
  report!: IReport | null;

  resultOfSourceType!: Subscription;
  resultOfChildrenSelected!: Subscription;
  resultOfStep!: Subscription;
  resultOfReport!: Subscription

  constructor(
    private dashboardService: DashboardService,
    private uploadService: UploadService,
    private snackbarService: SnackbarService,
    private router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public reportService: ReportService

  ) { }

  ngOnInit(): void {
    this.resultOfSourceType = this.reportService.sharedSourceType.subscribe(data => {
      this.sourceType = data;
    })
    this.resultOfChildrenSelected = this.reportService.sharedChildrenSelected.subscribe(data => {
      this.childrenSelected = data;

    })
    this.resultOfStep = this.reportService.sharedStep.subscribe(data => {
      this.step = data;
    })
    this.resultOfReport = this.reportService.sharedReport.subscribe(data => {
      this.report = data
    })
    this.initReportForm();

    this.getDataSources();
    this.getSections();
    this.getTags();
    this.getGroups();
    this.getAllELearnings();
    this.activatedRoute.paramMap
      .pipe(
        mergeMap((paramMap) => {
          const reportId = paramMap.get('id');
          if (reportId) {
            return this.dashboardService.getReportById(reportId)
          }
          this.editForm = false;

          this.reportService.newReport.next(null);
          this.initReportForm();
          if (this.sourceType == "sap-bo" || this.sourceType == "power-bi") {
            this.updateSelectedReports(this.childrenSelected);
          }
          return EMPTY;
        })
      )
      .subscribe((data) => {
        if (data) {
          this.sourceType = data.sourceType
          this.reportService.newStep.next(2)
          this.editForm = true;
          this.reportService.newReport.next(data)
          this.displayImageInput = this.report?.previewImageId ? false : true;
          this.displayAttachmentInput = this.report?.attachments?.length ? false : true;
          this.reportAttachments = this.report?.attachments as IAttachment[];
          this.initReportForm();

          if (['POWER_BI', 'SAP_BO', 'power-bi', 'sap-bo'].includes(this.sourceType)) {
            this.disableFormProperty('sourceName');
            this.disableFormProperty('url');
          }
          this.disableFormProperty('refreshDate');
        }
      }
        ,
        () => this.snackbarService.show('Sorry, something went wrong', 'error')

      );
    if (['POWER_BI', 'SAP_BO', 'power-bi', 'sap-bo'].includes(this.sourceType)) {
      this.disableFormProperty('sourceName');
      this.disableFormProperty('url');
    }
    this.disableFormProperty('refreshDate');
  }

  initReportForm(): void {
    if (this.editForm) {
      this.mapSourceType();
    }
    let formConfig = {
      visibility: [this.report?.visibility ?? "AVAILABLE"],
      sourceType: [this.sourceType ?? null, Validators.required],
      name: [this.report?.name, Validators.required],
      type: [this.report?.type ?? 'REPORT', Validators.required],
      description: [this.report?.description],
      datasources: [this.report?.datasources],
      refreshDate: [null],
      image: [null],
      sectionId: [this.report?.sectionId, Validators.required],
      codeGenerated: [false, Validators.required],
      code: [this.report?.code, [Validators.required, Validators.minLength(3)]],
      tags: [this.report?.tags ?? []],
      elearnings: [this.report?.elearnings?.map(el => el.id) ?? []],
      documents: [[]],
      groups: [this.report?.groups ?? []],
      pinned: [this.report?.pinned ?? false],
      sourceName: [this.report?.sourceName ?? null],
    }

    switch (this.sourceType) {
      case ('link'):
        formConfig = Object.assign(formConfig, {
          url: [this.report?.url, Validators.required],
          embedUrl: [this.report?.url],
        });
        return this.fillReportsForm([
          this.formBuilder.group(formConfig)
        ]);
      case ('file'):
        if (this.editForm) {
          this.displayFileInput = false;
        }
        this.fileName = this.report?.mainFile?.name || '';
        formConfig = Object.assign(formConfig, {
          mainFileId: [null, this.editForm ? null : Validators.required],
        });
        return this.fillReportsForm([
          this.formBuilder.group(formConfig)
        ]);
      case ('power-bi'):
        if (this.editForm) {
          formConfig = Object.assign(formConfig, {
            url: [this.report?.url]
          });
          return this.fillReportsForm([
            this.formBuilder.group(formConfig)
          ]);
        }
        return this.fillReportsForm([])
      case 'sap-bo':
        if (this.editForm) {
          formConfig = Object.assign(formConfig, {
            url: [this.report?.url]
          });
          return this.fillReportsForm([
            this.formBuilder.group(formConfig)
          ]);
        }
        return this.fillReportsForm([])
      default:
        this.fillReportsForm([]);
        break;
    }
  }

  mapSourceType(): void {
    switch (this.sourceType) {
      case sourceTypeEnum.POWER_BI:
        this.sourceType = reportSourceTypeEnum.POWER_BI
        return
      case sourceTypeEnum.SAP_BO:
        this.sourceType = reportSourceTypeEnum.SAP_BO
        return
      case sourceTypeEnum.FILE:
        this.sourceType = reportSourceTypeEnum.FILE
        return

      default:
        this.sourceType = reportSourceTypeEnum.LINK
        return;
    }
  }

  private fillReportsForm(formConfig: any): void {
    this.reportForm = this.formBuilder.group({
      reports: this.formBuilder.array(formConfig)
    });
  }

  updateSelectedReports(selectedReports: any): void {
    selectedReports.forEach((report: PlatformItem) => {
      this.reportsArray.push(this.insertReport(report));
    });
  }

  private insertReport(report: PlatformItem): FormGroup {
    let formConfig = {
      sourceParentId: report.parentId ?? null,
      sourceId: report?.id ?? null,
      sourceCuid: report?.cuid ?? null,
      sourceName: [report.name ?? null, Validators.required],
      name: [report.name ?? null, Validators.required],
      url: [report.url ?? null, Validators.required],
      embedUrl: report.embedUrl ?? null,
      visibility: ["AVAILABLE"],
      sourceType: [report.sourceType ?? null, Validators.required],
      type: [report.type ?? 'REPORT', Validators.required],
      description: [null],
      datasources: [[]],
      refreshDate: [null],
      image: [null],
      sectionId: [[], Validators.required],
      codeGenerated: [false, Validators.required],
      code: [null, [Validators.required, Validators.minLength(3)]],
      tags: [],
      elearnings: [],
      documents: [],
      groups: [],
      pinned: false,
    }
    return this.formBuilder.group(formConfig);
  }

  get reportsArray(): FormArray {
    return this.reportForm.get("reports") as FormArray;
  }

  onDisplayImageInput(): void {
    this.displayImageInput = !this.displayImageInput;
  }

  getDataSources(): void {
    this.dashboardService.getDatasources().subscribe(dataSources => {
      this.dataSources = dataSources;
    });
  }

  getSections(): void {
    this.dashboardService.getSections(0, 50, 'order', false).subscribe(sections => {
      this.sections = sections.items;
    });
  }

  getGroups(): void {
    this.dashboardService.getGroups().subscribe(groups => {
      this.groups = groups.items;
    });
  }

  getTags(): void {
    this.dashboardService.getTags(this.skip).subscribe(tags => {
      this.tags = tags.items;
    });
  }

  getAllELearnings(): void {
    this.dashboardService.getELearnings(this.skip).subscribe(elearnings => {
      this.elearnings = elearnings.items;
    });
  }

  saveFile(event: any): void {
    this.uploadService.uploadFile(event.target.files[0]).subscribe((data) => {
      if (data) {
        this.idFile = data.id || null
      }
    },
      () => this.snackbarService.show('Sorry, something went wrong', 'error')
    );
  }

  saveAttachments(event: any, index: number): void {
    this.startUploadingAttachments = true;
    Array.from(event.target.files).forEach((file: any) => {
      this.uploadService.uploadFile(file).subscribe((data) => {
        this.startUploadingAttachments = false;
        if (data && data.id) {
          const idAttachment = this.attachments.findIndex(el => el.index === index);
          if (idAttachment > -1) {
            this.attachments[idAttachment]?.id?.push(data.id as number);
          } else {
            this.attachments.push({ 'id': [data.id], index });
          }
        }
      },
        () => {
          this.snackbarService.show('Sorry, something went wrong', 'error');
          this.startUploadingAttachments = false;
        }
      );
    });
  }

  saveImage(event: any, index: number): void {
    Array.from(event.target.files).forEach((image: any) => {
      this.uploadService.uploadFile(image).subscribe((data) => {
        this.startUploadingAttachments = false;
        if (data) {
          this.idImage = data.id || null
          const idImg = this.imageId.findIndex(el => el.index === index)
          if (idImg > -1) {
            this.imageId[idImg].id = data.id || null;
          } else
            this.imageId.push({ 'id': data.id || null, index })
        }
      },
        () => {
          this.snackbarService.show('Sorry, something went wrong', 'error');
          this.startUploadingAttachments = false;
        }
      );
    });
  }

  onDisplayFileInput(): void {
    this.displayFileInput = !this.displayFileInput;
  }

  onEnableFormProperty(property: string, i: number): void {
    ((this.reportForm.controls.reports as FormArray).controls[i] as FormGroup).controls[property].enable();
  }

  onDisableFormProperty(property: string, i: number): void {
    ((this.reportForm.controls.reports as FormArray).controls[i] as FormGroup).controls[property].setValue(null);
    ((this.reportForm.controls.reports as FormArray).controls[i] as FormGroup).controls[property].disable();
  }

  deleteAttachment(index: number): void {
    this.reportAttachments?.splice(index, 1);
  }

  saveReport(): void {
    let body = this.reportForm.getRawValue();
    switch (this.sourceType) {
      case reportSourceTypeEnum.LINK:
        body = {
          reports: [
            {
              ...this.reportForm.value.reports[0],
              embedUrl: this.reportForm.value?.reports[0].url,
              name: this.reportForm.value?.reports[0].name,
              sourceName: this.reportForm.value?.reports[0].name,
              sourceType: sourceTypeEnum.LINK,
              previewImageId: this.idImage ? this.idImage : this.report?.previewImageId,
              attachments: [... (this.attachments[0]) ? this.attachments[0].id : [], ...this.reportAttachments?.map(f => f?.id)]
            }
          ]
        };
        break;
      case reportSourceTypeEnum.FILE:
        body = {
          reports: [
            {
              ...this.reportForm.value.reports[0],
              mainFileId: this.idFile ? this.idFile : this.report?.mainFileId,
              name: this.reportForm.value?.reports[0].name,
              sourceName: this.reportForm.value?.reports[0].name,
              sourceType: sourceTypeEnum?.FILE,
              previewImageId: this.idImage ? this.idImage : this.report?.previewImageId,
              attachments: [... (this.attachments[0]) ? this.attachments[0].id : [], ...this.reportAttachments?.map(f => f?.id)]
            }
          ]
        };
        break;
      case reportSourceTypeEnum.POWER_BI:
        this.enableFormProperty('url');
        body.reports = (this.reportForm.getRawValue().reports).map((el: any, index: number) => {
          const idImg = this.imageId.findIndex(el => el.index === index)
          const idAttachment = this.attachments.findIndex(el => el.index === index)
          return {
            ...el, ...{
              sourceType: sourceTypeEnum?.POWER_BI,
              paginated: this.editForm ? this.report?.paginated : this.reportService.currentItem?.paginated,
              previewImageId: idImg > -1 ? this.imageId[idImg].id : this.report?.previewImageId,
              attachments: [... (idAttachment > - 1) ? this.attachments[idAttachment].id : [], ...this.reportAttachments?.map(f => f?.id)]
            }
          }
        })
        break;

      case reportSourceTypeEnum.SAP_BO:
        this.enableFormProperty('url');
        body.reports = (this.reportForm.getRawValue().reports).map((el: any, index: number) => {
          const idImg = this.imageId.findIndex(el => el.index === index);
          const idAttachment = this.attachments.findIndex(el => el.index === index);

          return {
            ...el, ...{
              sourceType: sourceTypeEnum?.SAP_BO,
              paginated: this.editForm ? this.report?.paginated : this.reportService.currentItem?.paginated,
              previewImageId: idImg > -1 ? this.imageId[idImg].id : this.report?.previewImageId,
              attachments: [... (idAttachment > - 1) ? this.attachments[idAttachment].id : [], ...this.reportAttachments?.map(f => f?.id)]
            }
          }
        })
        break;
    }

    body.reports[0].sourceType = this.sourceTypesLabels[body.reports[0].sourceType] ?? body.reports[0].sourceType;
    if (this.report?.id) {
      this.dashboardService.updateReport(body.reports[0], this.report?.id).subscribe((data) => {
        this.snackbarService.show('Update successfully', 'success');
        this.dashboardService.displayReports = true;
        this.dashboardService.sectionId = body.reports[0].sectionId;
        this.dashboardService.sectionName = this.sections.find(section => section.id === body.reports[0].sectionId)?.name || "" ;

        this.router.navigate(['/admin', { outlets: { admin: ['sections'] } }]);
      }, () => {
        this.snackbarService.show('Sorry, something went wrong', 'error')
      });
    } else {
      this.dashboardService.saveReport(body).subscribe((data) => {
        this.snackbarService.show('Create successfully', 'success');
        this.dashboardService.displayReports = true;
        this.dashboardService.sectionId = body?.reports[0].sectionId;
        this.dashboardService.sectionName = this.sections.find(section => section.id === body.reports[0].sectionId)?.name || "" ;
        this.router.navigate(['/admin', { outlets: { admin: ['sections'] } }]);
      }, () => {
        this.snackbarService.show('Sorry, something went wrong', 'error')
      });
    }
  }

  enableFormProperty(property: string): void {
    (this.reportForm.controls.reports as FormArray).controls.map((reportFormControl: any) => {
      reportFormControl.controls[property].enable();
    });
  }

  disableFormProperty(property: string): void {
    (this.reportForm?.controls.reports as FormArray).controls.map((reportFormControl: any) => {
      reportFormControl?.controls[property].disable();
    });
  }

  back(): void {
    if (!this.report) {
      this.reportService.newStep.next(1);
    }
    else {
      this.reportService.newReport.next(null);
      history.back();
    }
  }

  ngOnDestroy(): void {
    this.resultOfSourceType.unsubscribe();
    this.resultOfChildrenSelected.unsubscribe();
    this.resultOfStep.unsubscribe();
    this.resultOfReport.unsubscribe();
  }
}
